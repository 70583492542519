.mobile-menu-button {
    display: none; /* Hide by default on larger screens */
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
  }
  
  /* Menu list styles */
  .menu-list {
    display: flex;
    list-style: none;
  }
  
  /* Styles for open mobile menu */
  .open {
    display: block;
    position: absolute;
    top: 60px; /* Adjust this value based on your design */
    left: 0;
    width: 100%;
    background-color: #252222;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 999;
  }
  
  /* Media query for mobile menu button */
  @media screen and (max-width: 800px) {
    .mobile-menu-button {
      display: block;
    }
    
    .right-side .menu-list {
        display: none;
    }
  
    .menu-list.open {
      display: block;
    }
  }